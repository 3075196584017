import React from "react";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer"; // <-- make sure this is imported
import "./App.css";

function App() {
  return (
    <div>
      <NavigationBar />
      <main>
        <section>
          <section id="home">
            <h1>Mit jelent számomra a Lélekgardrób?</h1>
            <p>
              Hi, my name is [your name] and I'm a [your profession or area of
              expertise]. I'm passionate about [your interests or hobbies] and I
              love to [what you like to do in your free time].
            </p>
            <p>
              I graduated from [your university or college] with a degree in
              [your major] and have been working in the [your industry or field]
              for [number of years] years. I've worked on projects for clients
              such as [client names] and have received awards for my work on
              [project name or type of project].
            </p>
            <p>
              In my free time, I enjoy [your hobbies or interests]. I'm also a
              [your position or role] at [your organization or community group]
              where I [what you do at the organization or group].
            </p>
          </section>
          <section id="about">
            <h2>Magamról</h2>
            <div className="about-container">
              <div className="about-content">
                <p>
                  Hi, my name is [your name] and I'm a [your profession or area
                  of expertise]. I'm passionate about [your interests or
                  hobbies] and I love to [what you like to do in your free
                  time].
                </p>
                <p>
                  I graduated from [your university or college] with a degree in
                  [your major] and have been working in the [your industry or
                  field] for [number of years] years. I've worked on projects
                  for clients such as [client names] and have received awards
                  for my work on [project name or type of project].
                </p>
                <p>
                  In my free time, I enjoy [your hobbies or interests]. I'm also
                  a [your position or role] at [your organization or community
                  group] where I [what you do at the organization or group].
                </p>
              </div>
              <div className="about-photo">
                <img src="/Stock.jpeg" alt="Portrait" />
              </div>
            </div>
          </section>
          <section id="work">
            <h2>Munkáim</h2>
            <p>
              I've worked on a variety of projects, including [type of projects
              or clients]. Here are some examples of my recent work:
            </p>
            <p>
              If you're interested in working with me, please don't hesitate to
              get in touch. You can contact me at [your email address] or
              connect with me on [your social media profiles].
            </p>
            <div className="about-photo">
              <img src="/1.jpg" alt="Portrait" />
            </div>
          </section>

          <section id="contact">
            <div class="container">
              <h2>Kapcsolat</h2>
              <form onSubmit={handleSubmit}>
                <div class="form-row">
                  <div class="form-group">
                    <label for="name">Név</label>
                  </div>

                  <div class="form-group">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      class="input-field"
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="email">Email</label>
                  </div>
                  <div class="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      class="input-field"
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group">
                    <label for="message">Üzenet</label>
                  </div>
                  <div class="form-group">
                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      class="input-field"
                      required
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="submit-container">
                    <button class="submit-button" type="submit">
                      Küldés
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </section>

        <Footer />
      </main>
    </div>
  );
}

const handleSubmit = async (event) => {
  event.preventDefault();
  const nameInput = document.querySelector("#name");
  const emailInput = document.querySelector("#email");
  const messageInput = document.querySelector("#message");

  const name = nameInput.value;
  const email = emailInput.value;
  const message = messageInput.value;

  const token = await grecaptcha.execute(
    "6LehtKUkAAAAALnpgEl9yUBiHOXt_IlArzQfnrzj",
    { action: "submit" }
  );
  console.log({ name, email, message, token });
  const response = await fetch(
    "https://7zgfkvlvpk.execute-api.eu-central-1.amazonaws.com/message",
    {
      method: "POST",
      body: JSON.stringify({
        name: name,
        email: email,
        message: message,
        token: token, // Add the token to the request body
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  const result = await response.json();

  if (result && result.success) {
    console.log("Email sent successfully!");
  } else {
    console.log("Error sending email: " + result.error);
  }
};
export default App;
