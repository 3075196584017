import React from "react";
import "./NavigationBar.css";

function NavigationBar() {
  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav>
      <ul>
        <li className="logo">
          <a href="#home" onClick={(e) => handleLinkClick(e, "home")}>
            <img src="/images/logo.svg" alt="Logo" />
          </a>
        </li>
        <li>
          <a
            href="#home"
            data-nav="home"
            onClick={(e) => handleLinkClick(e, "home")}
          >
            Lélekgardrób
          </a>
        </li>
        <li>
          <a
            href="#about"
            data-nav="about"
            onClick={(e) => handleLinkClick(e, "about")}
          >
            Magamról
          </a>
        </li>
        <li>
          <a
            href="#work"
            data-nav="work"
            onClick={(e) => handleLinkClick(e, "work")}
          >
            Munkám
          </a>
        </li>
        <li>
          <a
            href="#contact"
            data-nav="work"
            onClick={(e) => handleLinkClick(e, "contact")}
          >
            Kapcsolat
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default NavigationBar;
