import React from "react";
import "./Footer.css";
import packageJson from "../package.json";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <p>&copy; 2023 JH. Minden jog fenntartva.</p>
        <p>Version: {packageJson.version}</p>
      </div>
    </footer>
  );
}

export default Footer;
